<template>
  <v-dialog
    v-model="showDialog"
    class="overflow-hidden"
    :style="{ width: `${width}px`, height: `${height}px` }"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card id="pictureCard" class="pos-rel d-flex justify-center align-center">
      <video v-show="!isPhotoTaken" id="camera" ref="camera" autoplay></video>

      <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas"></canvas>

      <div class="camera-actions-container">
        <v-btn icon :disabled="videoDevices.length === 1" @click="switchCamera">
          <v-icon>mdi-camera-flip</v-icon>
        </v-btn>
        <v-divider vertical class="mx-3" />
        <v-btn icon :disabled="isLoading" @click="takePhoto">
          <v-icon>{{
            isPhotoTaken ? 'mdi-camera-retake' : 'mdi-camera'
          }}</v-icon>
        </v-btn>
        <v-divider vertical class="mx-3" />
        <v-btn
          :disabled="!isPhotoTaken"
          color="primary"
          small
          elevation="0"
          @click="uploadImage"
        >
          <v-icon dense class="mr-2">mdi-file-upload</v-icon>Save
        </v-btn>
      </div>
    </v-card>

    <a
      id="downloadPhoto"
      download="my-photo.jpg"
      class="button"
      role="button"
    ></a>
  </v-dialog>
</template>

<script>
export default {
  name: 'TakePictureDialog',
  props: {
    displayDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isCameraOpen: false,
      isPhotoTaken: false,
      isLoading: false,
      link: '#',
      width: 0,
      height: 0,
      ratio: 16 / 9,
      videoDevices: [],
      videoDeviceIndex: 1,
    };
  },
  computed: {
    showDialog() {
      return this.displayDialog;
    },
  },
  watch: {
    displayDialog(value) {
      if (value) {
        this.createCameraElement();
        let width = window.innerWidth * 0.8;
        this.width = width;
        this.height = width / this.ratio;
      } else {
        this.isPhotoTaken = false;
        this.stopCameraStream();
      }
    },

    videoDeviceIndex(value) {
      this.isLoading = true;
      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: this.videoDevices[value].deviceId,
          },
        })
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
          alert("May the browser didn't support or there is some errors.");
        });
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },

    switchCamera() {
      if (this.videoDeviceIndex < this.videoDevices.length - 1) {
        this.videoDeviceIndex++;
      } else {
        this.videoDeviceIndex = 0;
      }
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices.enumerateDevices().then((devices) => {
        this.videoDevices = devices.filter(
          (device) => device.kind === 'videoinput'
        );
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
          alert("May the browser didn't support or there is some errors.");
        });
    },

    stopCameraStream() {
      if (!this.isLoading) {
        let tracks = this.$refs.camera.srcObject.getTracks();

        tracks.forEach((track) => {
          track.stop();
        });
      }
    },

    takePhoto() {
      this.isPhotoTaken = !this.isPhotoTaken;
      let container = document.getElementById('camera');

      let canvas = document.getElementById('photoTaken');
      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;

      const context = this.$refs.canvas.getContext('2d');
      context.drawImage(
        this.$refs.camera,
        0,
        0,
        container.offsetWidth,
        container.offsetHeight
      );
    },

    uploadImage() {
      const base64Canvas = document
        .getElementById('photoTaken')
        .toDataURL('image/jpeg')
        .split(';base64,')[1];

      this.$emit('uploadImage', base64Canvas);
    },
  },
};
</script>

<style scoped lang="scss">
.camera-actions-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(8px);
  border-radius: 5px;
  position: absolute;
  bottom: 10px;
  left: calc(50% - 100px);
  z-index: 1;
  padding: 12px 16px;
}
</style>
