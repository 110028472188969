<script>
import AntInput from '@/components/AntInput.vue';
import { mapGetters } from 'vuex';
import { createUUID } from '@/services/uuid-helper';
import { LaravelEchoClient } from '@/services/laravel-echo';
import { getBlackOrWhiteBasedOnBackground } from '@/services/color-helper';
import { executeCustomModuleCall } from '@/services/api/module.api';

export default {
  name: 'DhmeStationDashboardCompleteAssemblyDialog',
  components: { AntInput },
  props: {
    displayed: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      focusedTask: 'dhmeStationDashboard/focusedAssemblyTask',
      moduleId: 'dhmeStationDashboard/moduleId',
    }),

    showModal() {
      return this.displayed;
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    async completeAssembly() {
      try {
        await executeCustomModuleCall(
          this.project.id,
          this.moduleId,
          'completeAssembly',
          {
            task: this.focusedTask.id,
          }
        );
        this.focusedTask.status = 'closed';
        this.$emit('close');
        this.$emit('completed');
      } catch (e) {
        this.$store.commit('showNotification', {
          content: e.message,
          color: 'error',
        });
      }
    },
  },
};
</script>

<template>
  <v-dialog v-model="showModal" max-width="600px">
    <v-card>
      <v-card-title class="justify-center text-uppercase headline">
        Complete assembly for module
        {{ focusedTask?.assemblyItem?.id }}?
      </v-card-title>
      <v-card-text>
        <v-card-subtitle
          >This will move the item out of the assembly hall</v-card-subtitle
        >
      </v-card-text>
      <v-card-actions class="ant-border-top ant-dialog-actions-bg">
        <v-spacer />
        <v-btn
          color="error"
          text
          small
          :disabled="isLoading"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          small
          :loading="isLoading"
          @click="completeAssembly"
        >
          Complete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
::v-deep .v-chip__content {
  display: inline-block !important;
  white-space: nowrap;
  overflow: hidden;
  height: auto;
  text-overflow: ellipsis;
}

::v-deep .v-chip__close {
  position: absolute;
  top: 5px;
  right: 0;
  width: 24px;
}
</style>
